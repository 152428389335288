import React from 'react';
import { Flex, Space } from '@mantine/core';
import { ReactNode } from 'react';
import { Image, Text, Title } from '@mantine/core';

export interface EmptyStateProps {
  image?: ReactNode;
  title?: ReactNode;
  content?: ReactNode;
  details?: ReactNode;
  primaryAction?: ReactNode;
  secondaryAction?: ReactNode;
  center?: boolean;
}

/**
 * A placeholder component for when there is no data to display in a specific scenario.
 *
 * For general information about the pattern, see https://carbondesignsystem.com/patterns/empty-states-pattern.
 * For errors, see {@link ErrorState}.
 */
export function EmptyState({
  image,
  title,
  content,
  details,
  primaryAction,
  secondaryAction,
  center,
}: EmptyStateProps) {
  if (typeof image === 'string') {
    image = <Image src={image} width={150} fit="contain" withPlaceholder />;
  }

  if (typeof title === 'string') {
    title = (
      <Title order={3} size="h2" weight="normal">
        {title}
      </Title>
    );
  }

  if (typeof content === 'string') {
    content = (
      <Text component="p" m="0">
        {content}
      </Text>
    );
  }

  if (typeof details === 'string') {
    details = (
      <Text c="gray" size="sm">
        {details}
      </Text>
    );
  }

  return (
    <Flex direction="column" align={center ? 'center' : 'flex-start'}>
      {image}
      {image && title && <Space h="sm" />}
      {title}
      {content}
      {details}
      {(content || details) && (primaryAction || secondaryAction) && <Space h="md" />}
      {primaryAction}
      {secondaryAction}
    </Flex>
  );
}

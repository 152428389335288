import React from 'react';
import { usePiletApi } from '../piletApi';
import { EmptyState, EmptyStateProps } from './EmptyState';
import error from '../assets/error.svg';

export type ErrorStateProps = EmptyStateProps;

/**
 * A specialization of the {@link EmptyState} for errors which, unless manually overwritten,
 * uses default prop values for informing the user about an error.
 */
export function ErrorState(props: ErrorStateProps) {
  const api = usePiletApi();
  const translate = api?.translate ?? ((s) => s);

  return (
    <EmptyState
      {...props}
      image={props.image ?? error}
      title={props.title ?? translate('errorStateDefaultTitle')}
      content={props.content ?? translate('errorStateDefaultContent')}
    />
  );
}

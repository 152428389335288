import { ColorScheme, ColorSchemeProvider, MantineProvider } from '@mantine/core';
import React, { PropsWithChildren, useState } from 'react';
import { appTheme } from './theme';
import { SWRConfig } from 'swr';

export function AppProvider({ children }: PropsWithChildren) {
  const defaultColorScheme = 'light'; // possibly change to `useColorScheme()` if the system's theme is desired.
  const [colorScheme, setColorScheme] = useState<ColorScheme>(defaultColorScheme);
  const toggleColorScheme = (v?: ColorScheme) => setColorScheme(v ?? colorScheme == 'light' ? 'dark' : 'light');

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider theme={{ ...appTheme, colorScheme }} withGlobalStyles withNormalizeCSS>
        <SWRConfig>{children}</SWRConfig>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

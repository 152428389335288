import { Group, Image, Title } from '@mantine/core';
import logo from '../assets/logo.svg';
import React from 'react';

export function Logo() {
  return (
    <Group>
      <Image src={logo} height={32} width="auto" fit="contain" />
      <Title order={1} size="h4" fw="normal" c="dimmed">
        Cloud Portal
      </Title>
    </Group>
  );
}

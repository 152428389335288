import React from 'react';
import { Menu, useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { IconMoonStars, IconSun } from '@tabler/icons-react';
import { usePiletApi } from 'piral-core';

export function ToggleColorSchemeMenuItem() {
  // Whether we want to have dark mode support or not is currently not clear.
  // Let's not show a toggle unless we're in dev mode for the time being.
  if (process.env.NODE_ENV !== 'development') {
    return null;
  }

  const api = usePiletApi();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();

  return (
    <Menu.Item
      icon={
        colorScheme === 'light' ? (
          <IconMoonStars size="0.9rem" />
        ) : (
          <IconSun size="0.9rem" color={theme.colors.yellow[7]} />
        )
      }
      onClick={() => toggleColorScheme()}>
      {api.translate('toggleColorScheme', 'Toggle color scheme')}
    </Menu.Item>
  );
}

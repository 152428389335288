import { UnstyledButton, UnstyledButtonProps, Group, Avatar, Text, createStyles, Skeleton } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { usePiletApi } from 'piral-core';
import React, { forwardRef } from 'react';
import useSWRImmutable from 'swr/immutable';

const useStyles = createStyles((theme) => ({
  user: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
    },
  },
}));

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UserButtonProps extends UnstyledButtonProps {}

export const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(({ ...rest }: UserButtonProps, ref) => {
  const api = usePiletApi();
  const { data, error } = useSWRImmutable('/api/v1/userinfo');
  const { classes } = useStyles();

  return (
    <UnstyledButton ref={ref} className={classes.user} p="md" w="100%" {...rest}>
      <Group>
        <Skeleton visible={!data && !error} circle>
          <Avatar radius="xl" />
        </Skeleton>

        <div style={{ flex: 1 }}>
          <Skeleton visible={!data && !error}>
            <Text size="sm" weight={500}>
              {data?.name ?? api.translate('unknownUserName')}
            </Text>
          </Skeleton>

          <Skeleton visible={!data && !error}>
            <Text color="dimmed" size="xs">
              {data?.preferred_username ?? api.translate('unknownPreferredUserName')}
            </Text>
          </Skeleton>
        </div>

        <IconChevronRight size="0.9rem" stroke={1.5} />
      </Group>
    </UnstyledButton>
  );
});

import { MantineThemeOverride, rem } from '@mantine/core';

export const appTheme: MantineThemeOverride = {
  primaryColor: 'red',
  primaryShade: 5,
  globalStyles: (theme) => ({
    body: {
      backgroundColor: theme.colorScheme === 'light' ? '#FDFDFD' : theme.colors.dark[9],
    },
  }),
  components: {
    NavLink: {
      // This styles any NavLink to resemble the design found at https://ui.mantine.dev/component/navbar-links-group.
      styles: (theme) => ({
        root: {
          padding: `${theme.spacing.sm} ${theme.spacing.md}`,
        },
        children: {
          borderLeft: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]}`,
          padding: 0,
          marginLeft: rem(30),
        },
      }),
    },
  },
  colors: {
    // These colors overrides come from the following Figma project:
    // https://www.figma.com/file/IsfEz8ov5uV3gde3KgBsFz/CP%3A-Planning
    //
    // Note that those color palettes only contain 8 colors while Mantine expects 10.
    // The outermost colors (i.e., the first and last value in the following arrays) are therefore "invented" for the moment.
    gray: [
      '#F5F7FA',
      '#F0F3F5',
      '#E1E3E6',
      '#D0D4D9',
      '#B2B7BD',
      '#6F757A',
      '#585D61',
      '#373A3D',
      '#242729',
      '#16181A',
    ],
    red: ['#FFD2CE', '#FFC6C2', '#FFB3AD', '#FF8D85', '#FF675C', '#F5473B', '#E0392D', '#CC2418', '#B81307', '#9F0000'],
    orange: [
      '#FFFAE6',
      '#FFE0CC',
      '#FFCEAD',
      '#FFB685',
      '#FF9D5C',
      '#FF8533',
      '#F5741D',
      '#E05F09',
      '#CC5608',
      '#B33D00',
    ],
    yellow: [
      '#ffffd5',
      '#FFEBC2',
      '#FFDD99',
      '#FFCF70',
      '#FFC247',
      '#FFB41F',
      '#F5A60A',
      '#E09600',
      '#CC8800',
      '#b77a00',
    ],
    lime: [
      '#ffffd5',
      '#EBFFC2',
      '#DDFF99',
      '#C7F56C',
      '#B1E051',
      '#9BCC39',
      '#8CB833',
      '#7CA32E',
      '#6D8F28',
      '#628024',
    ],
    green: [
      '#ddffef',
      '#C9F7DA',
      '#A9EFC1',
      '#85ECA8',
      '#63E08D',
      '#39CC6A',
      '#25B856',
      '#14A343',
      '#118F3B',
      '#0f8035',
    ],
    teal: ['#ccffff', '#BAF5F1', '#8AE5DF', '#49CCC3', '#1AB8AD', '#07A399', '#00885', '#007A72', '#00665F', '#005b55'],
    blue: [
      '#d5ffff',
      '#C2EBFF',
      '#8AD8FF',
      '#5CC9FF',
      '#33BBFF',
      '#0AA6F4',
      '#0088CC',
      '#0074AD',
      '#005F8F',
      '#005580',
    ],
    indigo: [
      '#f6faff',
      '#E0E4FF',
      '#ADB8FF',
      '#8595FF',
      '#6373E0',
      '#495BCC',
      '#2538B8',
      '#1427A3',
      '#11228F',
      '#0f1e80',
    ],
    violet: [
      '#f6faff',
      '#E0E4FF',
      '#ADB8FF',
      '#8595FF',
      '#6373E0',
      '#495BCC',
      '#2538B8',
      '#1427A3',
      '#11228F',
      '#0f1e80',
    ],
    pink: [
      '#ffd5eb',
      '#FFC2D6',
      '#FF99BB',
      '#F56C99',
      '#E05181',
      '#CC396A',
      '#B82556',
      '#A31443',
      '#8F113B',
      '#800f35',
    ],
  },
};

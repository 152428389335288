import React from 'react';
import { createRoot } from 'react-dom/client';
import { createInstance, Piral, createStandardApi, setupLocalizer } from 'piral';
import { createFetchApi } from 'piral-fetch';
import {
  DashboardContainer,
  DashboardTile,
  ErrorInfo,
  Layout,
  LoadingIndicator,
  MenuContainer,
  MenuItem,
  ModalsDialog,
  ModalsHost,
  NotFoundError,
  NotificationsHost,
  NotificationsToast,
} from './components';
import { AppProvider } from './AppProvider';
import { defaultMenuItems } from './defaults';
import messagesEn from './locales/en.json';

const feedUrl = 'https://ruby-cloud-portal.my.piral.cloud/api/v1/pilet';
const instance = createInstance({
  state: {
    components: {
      DashboardContainer,
      DashboardTile,
      ErrorInfo,
      Layout,
      LoadingIndicator,
      MenuContainer,
      MenuItem,
      ModalsDialog,
      ModalsHost,
      NotificationsHost,
      NotificationsToast,
    },
    errorComponents: {
      not_found: NotFoundError,
    },
  },
  plugins: [
    ...createStandardApi({
      menu: { items: defaultMenuItems },
      locale: setupLocalizer({
        messages: {
          en: messagesEn,
        },
      }),
    }),
    createFetchApi({
      default: {
        credentials: 'include',
      },
    }),
  ],
  requestPilets() {
    return fetch(feedUrl)
      .then((res) => res.json())
      .then((res) => res.items);
  },
});

const root = createRoot(document.querySelector('#app')!);
root.render(
  <AppProvider>
    <Piral instance={instance} />
  </AppProvider>,
);

import { Container } from '@mantine/core';
import { ErrorInfoProps, SwitchErrorInfo, usePiletApi } from 'piral';
import React from 'react';

export function ErrorInfo(props: ErrorInfoProps) {
  const { translate } = usePiletApi();

  return (
    <Container>
      <h2>{translate('errorInfoTitle')}</h2>
      <SwitchErrorInfo {...props} />
    </Container>
  );
}

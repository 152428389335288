import React from 'react';
import { IconDashboard } from '@tabler/icons-react';
import { InitialMenuItem, usePiletApi } from 'piral';
import { ToggleColorSchemeMenuItem } from './components';
import { MenuNavLink } from '@wuh/cloud-portal-lib';
import { LogOutMenuItem } from './components/LogOutMenuItem';

export const defaultMenuItems: Array<InitialMenuItem> = [
  {
    component: () => (
      <MenuNavLink to="/" match="/" isExact icon={<IconDashboard />} label={usePiletApi().translate('dashboardLink')} />
    ),
  },
  {
    settings: { type: 'user' },
    component: ToggleColorSchemeMenuItem,
  },
  {
    settings: { type: 'user' },
    component: LogOutMenuItem,
  },
];

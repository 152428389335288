import { Container, Grid } from '@mantine/core';
import { DashboardContainerProps, usePiletApi } from 'piral';
import React from 'react';

export function DashboardContainer({ children }: DashboardContainerProps) {
  const api = usePiletApi();

  return (
    <Container size="xl">
      <h2>{api.translate('dashboardPageTitle')}</h2>
      <Grid>{children}</Grid>
    </Container>
  );
}

import { Menu, Navbar, ScrollArea, createStyles, rem, NavbarProps } from '@mantine/core';
import { Menu as PiralMenu } from 'piral';
import React from 'react';
import { Logo } from './Logo';
import { UserButton } from './UserButton';

const useStyles = createStyles((theme) => {
  const sectionBorder = `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]}`;

  return {
    nav: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    },

    linksSection: {
      '& a': {
        textDecoration: 'none',
      },
    },

    header: {
      borderBottom: sectionBorder,
    },

    footer: {
      borderTop: sectionBorder,
    },
  };
});

export interface SideNavProps extends Omit<NavbarProps, 'children'> {
  minimal: boolean;
}

export function SideNav({ minimal, ...rest }: SideNavProps) {
  const { classes } = useStyles();

  return (
    <Navbar className={classes.nav} {...rest}>
      {!minimal && (
        <Navbar.Section className={classes.header} p="md">
          <Logo />
        </Navbar.Section>
      )}
      <Navbar.Section className={classes.linksSection} component={ScrollArea} px="sm" py="xl" grow>
        <PiralMenu type="general" />
      </Navbar.Section>
      <Navbar.Section className={classes.footer}>
        <Menu position={minimal ? 'top-end' : 'right'} withArrow>
          <Menu.Target>
            <UserButton />
          </Menu.Target>
          <Menu.Dropdown>
            <PiralMenu type="user" />
          </Menu.Dropdown>
        </Menu>
      </Navbar.Section>
    </Navbar>
  );
}

import React, { PropsWithChildren, createContext, useContext } from 'react';
import type { PiletApi } from '@wuh/cloud-portal-shell';
import type { BaseComponentProps } from 'piral-core';

const piletApiContext = createContext<PiletApi>(undefined!);

/**
 * Wraps the given {@link Component} with a context that enables use of the {@link usePiletApi} hook
 * when registered as a Piral component.
 * @param Component The component to be wrapped.
 */
export function withPiletApi<T>(Component: React.ComponentType<T>) {
  return (props: PropsWithChildren<T> & BaseComponentProps) => (
    <piletApiContext.Provider value={props.piral}>
      <Component {...props} />
    </piletApiContext.Provider>
  );
}

/**
 * When used in conjunction with {@link withPiletApi}, returns the {@link PiletApi} which was passed
 * to the pilet from which this hook is called.
 *
 * **Important:** This hooks should not be used in the app shell. Use the `usePiletApi` hook from
 * Piral instead.
 * @returns The {@link PiletApi}.
 */
export function usePiletApi() {
  return useContext(piletApiContext);
}

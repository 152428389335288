/**
 * Converts the given date to a display string that displays the date only.
 * @param date The date to be formatted. Can be any object that is accepted by the {@link Date} constructor.
 * @returns `undefined` if {@link date} is falsy; the formatted date string otherwise.
 */
export function formatDate(date?: string | number | Date) {
  return format(date, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}

/**
 * Converts the given date to a display string that displays the time only.
 * @param date The date to be formatted. Can be any object that is accepted by the {@link Date} constructor.
 * @returns `undefined` if {@link date} is falsy; the formatted time string otherwise.
 */
export function formatTime(date?: string | number | Date) {
  return format(date, {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
}

/**
 * Converts the given date to a display string that displays the date and time.
 * @param date The date to be formatted. Can be any object that is accepted by the {@link Date} constructor.
 * @returns `undefined` if {@link date} is falsy; the formatted date-time string otherwise.
 */
export function formatDateTime(date?: string | number | Date) {
  return format(date, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
}

function format(date: undefined | string | number | Date, options: Intl.DateTimeFormatOptions) {
  if (!date) {
    return undefined;
  }

  return new Date(date).toLocaleString(undefined, options);
}

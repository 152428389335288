import { Menu } from '@mantine/core';
import { IconLogout } from '@tabler/icons-react';
import { usePiletApi } from 'piral';
import React from 'react';

export function LogOutMenuItem() {
  const { translate } = usePiletApi();

  return (
    <a href="/auth/logout" style={{ textDecoration: 'none' }}>
      <Menu.Item icon={<IconLogout size="0.9rem" />}>{translate('logOutMenuItem')}</Menu.Item>
    </a>
  );
}

import { DefaultMantineColor, Flex, Center, ThemeIcon, Skeleton, Text } from '@mantine/core';
import React from 'react';
import { ReactNode } from 'react';

interface TileDetailsProps {
  icon?: ReactNode;
  iconColor?: DefaultMantineColor;
  count?: ReactNode;
  hint?: ReactNode;
  isLoading?: boolean;
}

/**
 * A component for displaying the generic "count of something" detail information in a typical
 * cloud portal dashboard tile.
 */
export function CountTileDetails({ icon, iconColor, count, hint, isLoading }: TileDetailsProps) {
  return (
    <Flex direction="column" style={{ flex: '1 1 0px' }}>
      <Center>
        {icon && (
          <ThemeIcon variant="light" color={iconColor} mr="xs">
            {icon}
          </ThemeIcon>
        )}
        <Skeleton visible={isLoading} w="auto">
          <Text fw="bold">{count ?? '--'}</Text>
        </Skeleton>
      </Center>
      <Text c="dimmed" size="xs" align="center">
        {hint}
      </Text>
    </Flex>
  );
}

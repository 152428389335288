import React from 'react';
import { ErrorState } from '@wuh/cloud-portal-lib';
import notFound from '../assets/not-found.svg';
import { Link } from 'react-router-dom';
import { Button } from '@mantine/core';
import { usePiletApi } from 'piral-core';

export function NotFoundError() {
  const { translate } = usePiletApi();

  return (
    <ErrorState
      title={translate('notFoundTitle')}
      content={translate('notFoundContent')}
      image={notFound}
      primaryAction={
        <Link to="/">
          <Button>{translate('notFoundGoHome')}</Button>
        </Link>
      }
    />
  );
}

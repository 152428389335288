import React, { ReactNode } from 'react';
import { Link, LinkProps, useRouteMatch } from 'react-router-dom';
import { ThemeIcon, NavLink } from '@mantine/core';

export interface MenuNavLinkProps {
  isExact?: boolean;
  match?: Parameters<typeof useRouteMatch>[0];
  to?: LinkProps['to'];
  label?: ReactNode;
  icon?: ReactNode;
  children?: ReactNode;
}

export function MenuNavLink({ isExact, match, to, label, icon, children }: MenuNavLinkProps) {
  const routeMatch = !!match && useRouteMatch(match);
  const isActive = Boolean(routeMatch && isExact ? routeMatch?.isExact : routeMatch);
  const navLink = (
    <NavLink
      variant="subtle"
      active={isActive}
      defaultOpened={isActive}
      label={label}
      icon={
        icon && (
          <ThemeIcon variant="light" color={isActive ? 'red' : 'gray'}>
            {icon}
          </ThemeIcon>
        )
      }>
      {children}
    </NavLink>
  );

  return to ? <Link to={to}>{navLink}</Link> : navLink;
}
